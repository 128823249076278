import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import "./GuidesCardComponent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const GuidesCardComponent = ({ data }) => {
  // console.log(data)
  return (
    <div className="guides_card_wrapper">
      <Container>
        <div className="guides_card_container">
          <ScrollAnimation
            animateIn="animate__slideInUp"
            animateOnce
            delay={100}
            offset={100}
          >
            <h2 className="guides_card_heading">{data?.heading}</h2>
          </ScrollAnimation>
          <div className="guides_card_list">
            {data?.items?.map((item, index) => {
              return (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  animateOnce
                  delay={index * 200}
                  offset={100}
                >
                  <div className="guides_card_item" key={item?.cta_title}>
                    <div className="image-section">
                      <img src={item?.image?.url} alt={item?.cta_title} />
                    </div>
                    <p className="description">
                      <ContentModule Content={item?.content?.data?.content} />
                    </p>
                    <div className="guides_cta_section">
                      <CTALink
                        class="cta_link"
                        link={
                          item?.link
                            ? item?.link
                            : { external_link: item?.custom_link }
                        }
                        title={item?.cta_title}
                        target_window={
                          item?.link_type === "external"
                            ? "new_window"
                            : item?.target_window
                        }
                      />
                    </div>
                  </div>
                </ScrollAnimation>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GuidesCardComponent
