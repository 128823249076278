import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./CustomSlider.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const NextArrow =(props)=>{
  const { className, style, onClick } = props;
return(
  <div
    className={className}
    style={{ ...style, display: "block"}}
    onClick={onClick}
  >
    <i className="icon slider-arrow-right"/>
  </div>
)

}

const PrevArrow =(props)=>{
  const { className, style, onClick } = props;
return(
  <div
    className={className}
    style={{ ...style, display: "block"}}
    onClick={onClick}
  >
    <i className="icon slider-arrow-left"/>
  </div>
)

}

export const CustomSlider = ({
  children,
  className,
  slidecount,
  isQuote,
  offplan,
  dots,
  autoPlaySlide,
  teamProperty,
  showMeThree,
  showTwo,
  isVacancies
}) => {

  const {isTablet,isMobile}=useDeviceMedia()

  const options = {
    dots: dots ? true : false,
    arrows:isVacancies?true:teamProperty&&!isTablet?true:false,
    infinite: offplan||autoPlaySlide ? true : false,
    speed:offplan?2000:800,
    slidesToShow: slidecount,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: offplan||autoPlaySlide ? true : false,
    autoplaySpeed: 3000,
    nextArrow:<NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: offplan||autoPlaySlide ? true : false,
          dots: dots||isVacancies ? true : false,
        },
      },
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: offplan||autoPlaySlide ? true : false,
          dots: dots||isVacancies ? true : false,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: offplan||autoPlaySlide ? true : false,
          dots: dots||isVacancies ? true : false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: showTwo?2: offplan ? 1 : isQuote ? 1 : 3,
          slidesToScroll: 1,
          dots: offplan ? false : isQuote ? false : true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow:showMeThree?3: offplan ? 1 : isQuote ? 1 : 2,
          slidesToScroll: 1,
          dots: offplan ? false : isQuote ? false : true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: offplan ? false : isQuote ? false : true,
        },
      },
    ],
  }

  return (
    <Slider className={`custom-slider ${className}`} {...options}>
      {children}
    </Slider>
  )
}

export default CustomSlider
