import { graphql } from "gatsby"
import React, { useEffect } from "react"
import ClientVideos from "../components/ClientVideos/ClientVideos"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import LandingBanner from "../components/LandingBanner/LandingBanner"
import ReviewsListing from "../components/ReviewsListing/ReviewsListing"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ClientReviewsLandingTemplate = ({ data,location }) => {
  const PageData = data?.strapiPage
  const { state } = location
  const reviewdata = data?.allStrapiReview?.edges
  const reviewData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      let getId=document.getElementById("review-comp");
      if(getId){
        getId.scrollIntoView({behavior:"smooth"})
      }
    }, 500)
  },[])

  return (
    <Layout popUpData={popUpData}>
      <LandingBanner pageData={PageData} reviewData={reviewData} />

      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" && (
              <GuidesCardComponent data={item} />
            )}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" &&
              item?.select_module === "review" && (
                <ReviewsListing data={reviewdata} item={item} revieweeData={state?.revieweeData}/>
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_CLIENT_VIDEO" && (
              <ClientVideos pageData={PageData} data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }
  let pageImg = ""
  if (Object.keys(myimgtransforms)?.length > 0) {
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt?.length>2?pageImg_filt[2]:PageData?.banner?.image?.url;
  }
  const imageUrl=pageImg

  // const imageUrl=PageData?.banner?.image?.url;



  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl={imageUrl}

    />
  )
}

export default ClientReviewsLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    allStrapiReview(
      filter: { publish: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          strapi_id
          rating
          title
          department
          name
          reviewer_name
          createdAt
          review_date
          email
          content {
            data {
              content
            }
          }
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          reviews_count {
            data {
              reviews_count
            }
          }
          star_rating
        }
      }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          id
          __typename
          select_module
          title

          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_CLIENT_VIDEO {
          id
          __typename
          add_item {
            designation
            name
            image {
              url
            }
            video_url
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          __typename
          id
          heading

          items {
            cta_title
            content {
              data {
                content
              }
            }
            image {
              url
              alternativeText
            }
            link {
              ...MenuFragment
            }
            cta_title
            custom_link
          }
        }
      }
    }
  }
`
