import React, { useState,useEffect } from "react"
import { Container } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import InnerPagination from "../InnerPagination/InnerPagination"
import Rating from "../Rating/Rating"
import "./ReviewsListing.scss"
import dateFormat from "dateformat"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const ReviewsListing = ({ data, item,revieweeData }) => {
  var allCategoryOption = {
    label: "Filter by department",
    value: "all",
  }

  // const dataWithName=data?.filter(item => item.node?.reviewer_name !== null)
  // const dataWithoutName = data?.filter(item => item.node?.reviewer_name===null)
  // const sortdData=[...dataWithName, ...dataWithoutName]

  // const sortdData=data

  const sortdData=data?.sort((a,b)=>{
    if (a.node?.review_date === null && b.node?.review_date === null) {
      return 0;
    } else if (a.node?.review_date === null) {
      return 1; // Null values at the end
    } else if (b.node?.review_date === null) {
      return -1; // Null values at the beginning
    }
  
    // Compare dates
    return b.node?.review_date - a.node?.review_date
  }
  )

  

  var allTeamNameOption = {
    label: "Filter by person",
    value: "all",
  }

  let teamMemberDefault={
    label: revieweeData?.revieweeName,
    value: revieweeData?.revieweeEmail
  }

  // const [search, setSearch] = useState("")
  const [selectedTeams, setSelectedTeams] = useState(sortdData)
  const [selectedOption, setSelectedOption] = useState(allCategoryOption)
  const [selectedTeamOption, setSelectedTeamOption] =
    useState(revieweeData?.revieweeEmail?teamMemberDefault:allTeamNameOption)

  let categoryOption = []

  //getting review data which has name
  // const sortedTeam=data.sort((a,b)=>a.node?.team?.name?.localeCompare(b.node?.team?.name))

  const getTeamName = sortdData?.filter(item => {
    if (item.node?.name) {
      return item
    }
  })

  //getting review data which has department
  // let getDepartment = data ? data : ""
  // getDepartment?.filter(item => {
  //   if (item.department) {
  //     // item?.department?.forEach(element => {
  //     //   if (element.department) {
  //     categoryOption.push({
  //       label: item.department?.replace("_", " "),
  //       value: item.department,
  //     })
  //     // }
  //     // })
  //   }
  // })
  const getDepartment = sortdData?.filter(item => {
    if (item.node?.department) {
      return item
    }
  })
  getDepartment.forEach(element => {
    if (element.node.department) {
      categoryOption.push({
        label: element.node.department?.replace(/_/g, " "),
        value: element.node.department,
      })
    }
  })

  categoryOption.sort((a, b) => a.label?.localeCompare(b.label))
  categoryOption.unshift(allCategoryOption)

  let teamNameOption = []

  getTeamName.forEach(element => {
    if (element.node?.email) {
      teamNameOption.push({
        label: element.node?.name,
        value: element.node?.email,
      })
    }
  })

  

  teamNameOption.sort((a, b) => a.label?.localeCompare(b.label))
  teamNameOption.unshift(allTeamNameOption)
  // console.log(categoryOption)
  const label = categoryOption.map(({ value }) => value)
  // function removeDuplicates(arr) {
  //   return arr.filter((item, index) => arr.indexOf(item) === index)
  // }
  // console.log(removeDuplicates(categoryOption))
  // console.log(label)
  let categoriesOptionList = categoryOption.filter(
    ({ value }, index) => !label.includes(value, index + 1)
  )
  // console.log(categoriesOptionList)
  const teamlabel = teamNameOption.map(({ value }) => value)
  let teamNamesOptionList = teamNameOption.filter(
    ({ value }, index) => !teamlabel.includes(value, index + 1)
  )

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedTeams,
    itemsPerPage,
  })
  // for pagination

  const handleTabChange = option => {
    setSelectedOption(option)
    setSelectedTeamOption(allTeamNameOption)
    if (option.value === "all") {
      setSelectedTeams(sortdData)
      return
    }
    let filteredTeams = []
    sortdData.filter(item => {
      if (item?.node?.department) {
        if (item?.node?.department?.includes(option.value)) {
          filteredTeams.push(item)
        }
      }
    })

    // data.filter(team => {
    //   if (team.node?.team?.team_member?.length > 0) {
    //     team.node?.team?.team_member?.filter(member => {
    //       if (member?.slug?.includes(option.value)) {
    //         filteredTeams.push(team)
    //       }
    //     })
    //   }
    // })

    setSelectedTeams(filteredTeams)
    setCurrentPage(1)
  }

   useEffect(()=>{
    if(revieweeData?.revieweeEmail){
    let filteredTeams = []
    sortdData.filter(team => {
      if (team.node?.email) {
        if (team.node.email?.includes(revieweeData?.revieweeEmail)) {
          filteredTeams.push(team)
        }
      }
    })


    setSelectedTeams(filteredTeams)
  }
  },[revieweeData?.revieweeEmail])

  const handleTabChangeName = option => {
    setSelectedTeamOption(option)
    setSelectedOption(allCategoryOption)
    if (option.value === "all") {
      setSelectedTeams(sortdData)
      return
    }
    let filteredTeams = []
    sortdData.filter(team => {
      if (team.node?.email) {
        // team.node.team?.filter(member => {
        if (team.node?.email?.includes(option.value)) {
          filteredTeams.push(team)
        }
        // })
      }
    })

    setSelectedTeams(filteredTeams)
    setCurrentPage(1)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [reviewid, setReviewid] = useState(false)
  const handleShow = event => {
    setReviewid(event)
    setShow(true)
  }

  return (
    <div className="review-listing-wrapper" id={revieweeData?.revieweeEmail?"review-comp":""}>
      <Container>
        <div className="review-opp-content">
          <h2 className="title">{item.title}</h2>
          <p className="description">
            <ContentModule Content={item?.content?.data?.content} />
          </p>
        </div>
        <div className="review-sorting-section">
          <div className="select-department">
            <Select
              options={teamNamesOptionList}
              isSearchable={false}
              className={"category-select"}
              classNamePrefix={"category-select"}
              //   hideSelectedOptions={true}
              value={selectedTeamOption}
              onChange={handleTabChangeName}
            />
            <span>
              <i className="icon grey-down-arrow" />
            </span>
          </div>
          <div className="select-department">
            <Select
              options={categoriesOptionList}
              isSearchable={false}
              className={"category-select"}
              classNamePrefix={"category-select"}
              //   hideSelectedOptions={true}
              value={selectedOption}
              onChange={handleTabChange}
            />
            <span>
              <i className="icon grey-down-arrow" />
            </span>
          </div>
        </div>

        <div class="masonry">
          {currentItems?.map(({ node, i }) => {
            return (
              <div class="item">
               
               {node?.reviewer_name&&
                <h5>{node?.reviewer_name}</h5>
               }
                {node?.review_date&&
                  <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                }
                <Rating starRating={node?.rating} />
                
                <p className="description-review">
                <ContentModule
                  Content={
                    node?.content?.data?.content &&
                    node?.content?.data?.content.length > 220
                      ? node?.content?.data?.content.substring(0, 220) + "... "
                      : node?.content?.data?.content
                  }
                />
                </p>
                {node?.content?.data?.content &&
                  node?.content?.data?.content.length > 220 && (
                    <a
                      href="javascript:void(0);"
                      className="btn-text-link"
                      onClick={() => handleShow(node?.strapi_id)}
                    >
                      more
                    </a>
                  )}

                {reviewid && reviewid == node.strapi_id && (
                  <Modal
                    show={show && reviewid == node.strapi_id}
                    size="lg"
                    centered={true}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="modal-60w modal-form reviewPopup"
                  >
                    <div className="review-wrap">
                      <Modal.Header closeButton className="contact-close-btn">
                        <Modal.Title className="w-100">
                          {node?.title}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {node?.reviewer_name&&
                         <h5>{node?.reviewer_name}</h5>
                        }
                        {node?.review_date&&
                          <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                        }
                        <Rating starRating={node?.rating} />
                        <ContentModule
                          Content={node?.content?.data?.content}
                        />{" "}
                      </Modal.Body>
                    </div>
                  </Modal>
                )}
              </div>
            )
          })}
        </div>
        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedTeams.length}
          setCurrentPage={setCurrentPage}
          listingName="Review"
        />
      </Container>
    </div>
  )
}

export default ReviewsListing
